import React from 'react';

import styles from './styles.module.scss';
import { ConstantRow } from '../ConstantRow/ConstantRow';
import { ButtonsToSelects } from '../ButtonsToSelects';

export const SearchFilter = () => (
  <div className={styles.container}>
    <ConstantRow />
    <ButtonsToSelects />
  </div>
);
