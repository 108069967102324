import React from 'react';
import { Table, Checkbox, Button } from 'factor';
import { connect } from 'react-redux';

import styles from './styles.module.scss';

import { getRowClassName } from '../helpers';

import SelectedCampaigns from './SelectedCampaigns';

import { AppState } from '../../store';
import { applicationActions } from '../../store/app/actions';

class InventoryTableComponent extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterColumns: null,
    };
  }

  getHeader = () => {
    return [
      { label: '', className: '_checkbox' },
      { label: 'Audience Name', className: '_audience-name', sortingKey: 'audienceName' },
      { label: 'eCPM', className: '_ecpm', sortingKey: 'ecpm' },
      { label: 'Match Rate', className: '_match-rate', sortingKey: 'matchRate' },
      { label: 'White List', className: '_white-list', sortingKey: 'whiteList' },
      { label: 'Black List', className: '_black-list', sortingKey: 'blackList' },
    ];
  };

  getBody = () => {
    const { tableData } = this.props;
    const { data } = tableData;

    const selectedLength = data.filter((i: any) => i.selected).length;

    return [
      {
        key: (d: any) =>
          d.total ? (
            <Checkbox
              className={selectedLength && !d.selectedAll ? '_dirty' : ''}
              checked={d.selectedAll}
              name={`checkbox-id-${d.id}`}
            />
          ) : (
            <Checkbox checked={d.selected} name={`checkbox-id-${d.id}`} />
          ),
        className: '_checkbox',
      },
      {
        key: (d: any) => {
          if (d.total) {
            return <span className="table__cell-total">Total</span>;
          }
          return d.audienceName;
        },
        className: '_audience-name',
      },
      { key: 'ecpm', className: '_number _ecpm' },
      { key: 'matchRate', className: '_number _match-rate' },
      { key: 'whiteList', className: '_number _white-list' },
      { key: 'blackList', className: '_number _black-list' },
    ];
  };

  toggleSelect = (d: any) => {
    const { getData, tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    const selectedRowData = tableData.find((i: any) => i.id === d.id);
    selectedRowData.selected = !selectedRowData.selected;
    selectedRowData.rowClassName = getRowClassName(
      selectedRowData.rowClassName,
      '_selected',
      selectedRowData.selected,
    );

    newData.unsortedData = {
      ...tableFirstRow,
      selectedAll: tableData.filter((i: any) => i.selected).length === tableData.length,
    };
    newData.data = tableData.map((i: any) => {
      return i.id === d.id ? { ...i } : i;
    });

    getData(newData);
  };

  toggleSelectAll = () => {
    const { getData, tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    const selectedLength = tableData.filter((i: any) => i.selected).length;

    if (newData.selectedAll || selectedLength > 0) {
      newData.unsortedData = {
        ...tableFirstRow,
        selectedAll: false,
      };
      newData.data = tableData.map((i: any) => {
        /* eslint-disable */
        i.selected = false;
        i.rowClassName = getRowClassName(i.rowClassName, '_selected', false);
        /* eslint-enable */
        return { ...i };
      });
    } else {
      newData.unsortedData = {
        ...tableFirstRow,
        selectedAll: true,
      };
      newData.data = tableData.map((i: any) => {
        /* eslint-disable */
        i.selected = true;
        i.rowClassName = getRowClassName(i.rowClassName, '_selected', true);
        /* eslint-enable */
        return { ...i };
      });
    }
    getData(newData);
  };

  isSelectedRowsEqualExcluded = () => {
    const { tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;

    const selectedRows = tableData.filter((i: any) => i.selected);
    const excludedInSelectedRows = selectedRows.filter((i: any) => i.isExcluded);

    return selectedRows.length === excludedInSelectedRows.length;
  };

  toggleExclude = () => {
    const { getData, tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;

    const selectedRows = tableData.filter((i: any) => i.selected);

    if (this.isSelectedRowsEqualExcluded()) {
      selectedRows.forEach((i: any) => {
        /* eslint-disable */
        i.isExcluded = false;
        i.rowClassName = getRowClassName(i.rowClassName, '_excluded', false);
        /* eslint-disable */
      });
    } else {
      selectedRows.forEach((i: any) => {
        /* eslint-disable */
        i.isExcluded = true;
        i.rowClassName = getRowClassName(i.rowClassName, '_excluded', true);
        /* eslint-disable */
      });
    }

    getData(newData);
  };

  rowClickHandler = (e: any, data: any) => {
    const { tagName } = e.target;
    const elType = e.target.getAttribute('type');

    if (tagName === 'INPUT' && elType === 'checkbox') {
      if (data.total) {
        this.toggleSelectAll();
      } else {
        this.toggleSelect(data);
      }
    }
  };

  render() {
    const { tableData } = this.props;

    const { filterColumns } = this.state;

    const selectedLength = tableData.data.filter((i: any) => i.selected).length;

    const isSelectedRowsEqualExcluded = this.isSelectedRowsEqualExcluded();

    return (
      <div>
        <div className="d-flex align-items-center mb-3">
          {selectedLength > 0 && (
            <div className="d-flex align-items-center">
              <SelectedCampaigns />
              <Button
                className={`btn-round ${
                  isSelectedRowsEqualExcluded ? '_cornflower-blue' : '_pomegranate'
                } ml-3`}
                onClick={this.toggleExclude}
              >
                {isSelectedRowsEqualExcluded ? 'Include' : 'Exclude'}
              </Button>
              <div className={styles.legend}>
                <div className={styles.legendItem}>
                  <i className={`${styles.legendIcon} ${styles.exclude}`} />
                  <div className={styles.legendTitle}>Exclude</div>
                </div>
                <div className={styles.legendItem}>
                  <i className={`${styles.legendIcon} ${styles.include}`} />
                  <div className={styles.legendTitle}>Include</div>
                </div>
              </div>
            </div>
          )}
          {/* <ButtonCircle outline iconName="CSV" className="_size-20 ml-auto mr-3" /> */}
          {/* <Select */}
          {/* className={`${styles.select}`} */}
          {/* options={TEST_OPTIONS} */}
          {/* onChange={(value: any) => { */}
          {/* this.setState({ */}
          {/* filterColumns: value, */}
          {/* }); */}
          {/* }} */}
          {/* value={filterColumns} */}
          {/* placeholder="Select Columns" */}
          {/* isMulti */}
          {/* multiPlaceholder={(length: any) => `${length} Column${length > 1 ? 's' : ''} Selected`} */}
          {/* allSelectable */}
          {/* dnd */}
          {/* /> */}
        </div>
        <Table
          className={styles.table}
          header={this.getHeader()}
          body={this.getBody()}
          data={tableData.data}
          unsortedData={[tableData.unsortedData]}
          paging={null}
          fixedHeader
          offsetTop={70}
          rowKeyExtractor={(data: any) => data.id}
          rowClickHandler={this.rowClickHandler}
          windowFreeResizeEvent
        />
      </div>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    tableData: state.app.tableData,
  };
};

const mapActions = {
  getData: applicationActions.getData,
};

export const InventoryTable = connect(mapState, mapActions)(InventoryTableComponent);
