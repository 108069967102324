import React from 'react';
import { connect } from 'react-redux';
import { IQMRouter } from 'iqm-framework';

import { BASE_URL } from 'config';
import { authActions, Authorized, Logout } from 'store/auth/actions';
import { User } from 'models/User';
import { createIqmInstance } from 'api';
import { PrivateRoutes } from '../PrivateRoutes';

import './App.scss';

type Props = Authorized & Logout & {};

const AppComponent = (props: Props) => {
  const { authorized, logout } = props;

  const onSuccessLogin = (data: User) => {
    authorized(data);
    createIqmInstance({
      apiToken: data.apiToken,
      domainURL: data.domainURL,
    });
  };

  const onLogout = () => logout();

  return (
    <IQMRouter
      documentTitle="Inventory"
      successLoginRedirectPath={BASE_URL}
      onSuccessLogin={onSuccessLogin}
      onLogout={onLogout}
    >
      <PrivateRoutes />
    </IQMRouter>
  );
};

const mapAction = {
  authorized: authActions.authorized,
  logout: authActions.logout,
};

export const App = connect(null, mapAction)(AppComponent);
