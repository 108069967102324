import React from 'react';

import styles from './styles.module.scss';
import { Option } from '../../models/Option';

export const Stats = ({ list }: { list: Option[] }) => (
  <ul className={styles.list}>
    {list.map((item) => (
      <li className={styles.item}>
        <div className={styles.label}>{item.label}</div>
        <div className={styles.value}>{item.value}</div>
      </li>
    ))}
  </ul>
);
