import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'factor';

import styles from './styles.module.scss';
import { AppState } from '../../store';
import { applicationActions, SetMainTabValue } from '../../store/app/actions';

const TABS = [
  { title: 'Open Exchange', value: 'Open Exchange' },
  { title: 'Private Deal', value: 'Private Deal' },
  { title: 'Requested deals', value: 'Requested deals' },
];

interface Props extends SetMainTabValue {
  mainTabValue: string;
}

class MainTabsComponent extends React.Component<Props> {
  handleChange = (tab: { title: string; value: string }) => {
    const { setMainTabValue } = this.props;

    setMainTabValue(tab.value);
  };

  render() {
    const { mainTabValue } = this.props;

    return (
      <Tabs
        className={styles.tabs}
        items={TABS}
        onChange={this.handleChange}
        value={mainTabValue}
      />
    );
  }
}

const mapState = (state: AppState) => ({
  mainTabValue: state.app.mainTabValue,
});

const mapActions = {
  setMainTabValue: applicationActions.setMainTabValue,
};

export const MainTabs = connect(mapState, mapActions)(MainTabsComponent);
