import React from 'react';
import { Header } from 'iqm-framework';
import { Button, Icon } from 'factor';
import { MainTabs } from 'components/MainTabs/MainTabs';

const NavbarComponent = () => {
  return (
    <Header backButton={false} title="Inventory" leftLogo="Inventory">
      <MainTabs />
      <Button className="btn-round _md _filled _cornflower-blue">
        <span className="btn-content__icon">
          <Icon name="Plus" />
        </span>
        Inventory Group
      </Button>
    </Header>
  );
};

export const Navbar = NavbarComponent;
