import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'factor';

import { getRowClassName } from '../../helpers';

import styles from './styles.module.scss';
import { TableRow } from '../../../models/Table';
import { AppState } from '../../../store';
import { applicationActions } from '../../../store/app/actions';

class SelectedCampaignsComponent extends Component<any> {
  handleClear = () => {
    const { tableData: oldTableData, getData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    newData.unsortedData = {
      ...tableFirstRow,
      selectedAll: false,
    };
    newData.data = tableData.map((i: TableRow) => {
      /* eslint-disable */
      i.selected = false;
      i.rowClassName = getRowClassName(i.rowClassName, '_selected', false);
      /* eslint-enable */
      return { ...i };
    });

    getData(newData);
  };

  render() {
    const { tableData } = this.props;

    const selectedLength = tableData.data.filter((i: TableRow) => i.selected).length;

    return (
      <Button className={`btn-round _cornflower-blue ${styles.remove}`} onClick={this.handleClear}>
        <span>{`${selectedLength} Audience${selectedLength > 1 ? 's' : ''} selected`}</span>
        <span className="btn-content__icon">
          <Icon name="Close" />
        </span>
      </Button>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    tableData: state.app.tableData,
  };
};

const mapActions = {
  getData: applicationActions.getData,
};

const SelectedCampaigns = connect(mapState, mapActions)(SelectedCampaignsComponent);

export default SelectedCampaigns;
