import { reducerFromMap } from 'utils/actions';
import { Action } from 'models/Action';
import { applicationConstants } from './constants';
import { Option } from '../../models/Option';
import { Table } from '../../models/Table';
import { tableData } from './consts';

export interface ApplicationState {
  isSidebarOpen: boolean;
  mainTabValue: string;
  search: string;
  country: Option | null;
  politicalInclination: Option | null;
  tableData: Table;
}

const defaultApplicationState: ApplicationState = {
  isSidebarOpen: true,
  mainTabValue: 'Open Exchange',
  search: '',
  country: null,
  politicalInclination: null,
  tableData,
};

function toggleSidebar(state: ApplicationState): ApplicationState {
  return {
    ...state,
    isSidebarOpen: !state.isSidebarOpen,
  };
}

function setMainTabValue(state: ApplicationState, action: Action<string>): ApplicationState {
  return {
    ...state,
    mainTabValue: action.payload,
  };
}

function setSearchValue(state: ApplicationState, action: Action<string>): ApplicationState {
  return {
    ...state,
    search: action.payload,
  };
}

function setCountry(state: ApplicationState, action: Action<Option | null>): ApplicationState {
  return {
    ...state,
    country: action.payload,
  };
}

function setPoliticalInclination(
  state: ApplicationState,
  action: Action<Option | null>,
): ApplicationState {
  return {
    ...state,
    politicalInclination: action.payload,
  };
}

function getData(state: ApplicationState, action: Action<Table>): ApplicationState {
  return {
    ...state,
    tableData: action.payload,
  };
}

const reducer = reducerFromMap<ApplicationState>(defaultApplicationState, {
  [applicationConstants.TOGGLE_SIDEBAR]: toggleSidebar,
  [applicationConstants.SET_MAIN_TAB_VALUE]: setMainTabValue,
  [applicationConstants.SET_SEARCH_VALUE]: setSearchValue,
  [applicationConstants.SET_COUNTRY]: setCountry,
  [applicationConstants.SET_POLITICAL_INCLINATION]: setPoliticalInclination,
  [applicationConstants.GET_TABLE_DATA]: getData,
});

export const app = (state: ApplicationState = defaultApplicationState, action: Action<any>) =>
  reducer(state, action);
