import React from 'react';

import styles from './styles.module.scss';

interface Props {
  title: string;
  onCloseClick: () => void;
}

export const SidebarHeader = ({ title, onCloseClick = () => {} }: Props) => (
  <header className={styles.header}>
    <h4 className={styles.title}>{title}</h4>
    <button type="button" className={`btn-cross ${styles.close}`} onClick={onCloseClick} />
  </header>
);
