import React from 'react';

import { Stats } from '../Stats';
import { PortionsList } from '../PortionsList';
import { InventoryTable } from '../Table';

const STATS = [
  { label: 'Inventory', value: '1,737.427' },
  { label: 'Publishers', value: '737,427' },
  { label: 'Uniques', value: '1,500,000' },
  { label: 'Impressions', value: '147,767' },
];

export const MainPageContent = () => (
  <>
    <Stats list={STATS} />
    <PortionsList />
    <InventoryTable />
  </>
);
