import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'factor';

import { Navbar } from 'components/Navbar';
import styles from './styles.module.scss';
import { MainPageContent } from '../../components/MainPageContent/MainPageContent';
import { AppState } from '../../store';
import { applicationActions } from '../../store/app/actions';
import { Sidebar } from '../../components/Sidebar';

type Props = {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
};

const MainPageComponent = (props: Props) => {
  const { isSidebarOpen, toggleSidebar } = props;

  return (
    <>
      <Navbar />
      <Layout
        mainClassName={styles.main}
        opened={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        sidebar={<Sidebar />}
      >
        <MainPageContent />
      </Layout>
    </>
  );
};

const mapState = (state: AppState) => ({
  isSidebarOpen: state.app.isSidebarOpen,
});

const mapActions = {
  toggleSidebar: applicationActions.toggleSidebar,
};

export const MainPage = connect(mapState, mapActions)(MainPageComponent);
