import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'factor';

import styles from './styles.module.scss';

const BUTTONS = [
  {
    label: 'Source',
  },
  {
    label: 'County',
  },
  {
    label: 'Creative Size',
  },
  {
    label: 'Creative Type',
  },
  {
    label: 'Traffic Type',
  },
  {
    label: 'Device Type',
  },
  {
    label: 'Language',
  },
  {
    label: 'Operating System',
  },
  {
    label: 'IAB Category',
  },
];

export const ButtonsToSelectsComponent = () => (
  <div className={`${styles.container} mb-2`}>
    <ul className={styles.buttons}>
      {BUTTONS.map((btn) => (
        <li key={btn.label} className={styles.btn}>
          <Button className="btn-round _gray">
            <span className="btn-content__icon">
              <Icon name="Plus" />
            </span>
            {btn.label}
          </Button>
        </li>
      ))}
      <li className={styles.controls}>
        <Button className="btn-square _md _cornflower-blue">Reset</Button>
        <Button className="btn-square _md _filled _cornflower-blue">Search</Button>
      </li>
    </ul>
  </div>
);

export const ButtonsToSelects = connect()(ButtonsToSelectsComponent);
