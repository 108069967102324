import React from 'react';
import { Tooltip, Icon } from 'factor';

import styles from './styles.module.scss';
import { Option } from '../../models/Option';

interface Props {
  title: string;
  list: Option<number>[];
}

export const Portions = (props: Props) => {
  const { title, list } = props;

  const values = list.map((item) => item.value);
  const sum = values.reduce((acc, item) => acc + item);
  const min = Math.min(...values);
  const max = Math.max(...values);

  const getRatio = (v: number, minVal: number, maxVal: number) => {
    return (v - minVal) / (maxVal - min);
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        <Tooltip auto={false} position="left" label={title} className={styles.tooltip}>
          <Icon name="Question" />
        </Tooltip>
      </header>
      <div className={styles.parts}>
        {list.map((item) => {
          const ratio = getRatio(item.value, min, max);
          return (
            <Tooltip
              label={`${item.label}: ${item.value}%`}
              className={styles.part}
              style={{
                backgroundColor: `hsl(165, 65%, ${46 - 21 * ratio}%)`,
                minWidth: `${(item.value / sum) * 100}%`,
              }}
            />
          );
        })}
      </div>
      <ul className={styles.list}>
        {list.map((item) => {
          const ratio = getRatio(item.value, min, max);
          return (
            <li className={styles.item}>
              <div
                className={styles.value}
                style={{ backgroundColor: `hsl(165, 65%, ${46 - 21 * ratio}%)` }}
              >
                {item.value}%
              </div>
              <div className={styles.label}>{item.label}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
