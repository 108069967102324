import React from 'react';
import { connect } from 'react-redux';
import { TextField, Select } from 'factor';

import styles from './styles.module.scss';
import { AppState } from '../../store';
import {
  applicationActions,
  SetCountry,
  SetPoliticalInclination,
  SetSearchValue,
} from '../../store/app/actions';
import { Option } from '../../models/Option';
import { TEST_OPTIONS } from '../consts';

interface Props extends SetSearchValue, SetCountry, SetPoliticalInclination {
  search: string;
  country: Option | null;
  politicalInclination: Option | null;
}

const ConstantRowComponent = (props: Props) => {
  const {
    search,
    country,
    politicalInclination,
    setSearchValue,
    setCountry,
    setPoliticalInclination,
  } = props;

  return (
    <div className={`${styles.container} mb-3`}>
      <TextField
        className="mb-3"
        iconName="Search"
        placeholder="Search comma separated"
        label="Search"
        value={search}
        onChange={setSearchValue}
      />
      <Select
        className="mb-3"
        isSearchable
        options={TEST_OPTIONS}
        label="Country"
        placeholder="Country"
        value={country}
        onChange={setCountry}
      />
      <Select
        isSearchable
        options={TEST_OPTIONS}
        label="Political Inclinations"
        placeholder="Political Inclinations"
        value={politicalInclination}
        onChange={setPoliticalInclination}
      />
    </div>
  );
};

const mapState = (state: AppState) => ({
  search: state.app.search,
  country: state.app.country,
  politicalInclination: state.app.politicalInclination,
});

const mapActions = {
  setSearchValue: applicationActions.setSearchValue,
  setCountry: applicationActions.setCountry,
  setPoliticalInclination: applicationActions.setPoliticalInclination,
};

export const ConstantRow = connect(mapState, mapActions)(ConstantRowComponent);
