import { reducerFromMap } from 'utils/actions';
import { Action } from 'models/Action';
import { User } from 'models/User';
import { authConstants } from './constants';

export interface AuthState {
  user: User | null;
  authorized: boolean;
}

export const defaultAuthState: AuthState = {
  user: null,
  authorized: false,
};

function loginSuccess(state: AuthState, action: Action<User>): AuthState {
  return {
    ...state,
    user: action.payload,
    authorized: true,
  };
}

function logout(): AuthState {
  return {
    ...defaultAuthState,
  };
}

const reducer = reducerFromMap<AuthState>(defaultAuthState, {
  [authConstants.LOGIN_SUCCESS]: loginSuccess,
  [authConstants.LOGOUT]: logout,
});

export const auth = (state: AuthState = defaultAuthState, action: Action<any>) =>
  reducer(state, action);
