import { createAction } from 'utils/actions';
import { applicationConstants } from './constants';
import { Option } from '../../models/Option';
import { Table } from '../../models/Table';

export const applicationActions = {
  toggleSidebar() {
    return createAction<void>(applicationConstants.TOGGLE_SIDEBAR);
  },
  setMainTabValue(mainTabValue: string) {
    return createAction<string>(applicationConstants.SET_MAIN_TAB_VALUE, mainTabValue);
  },
  setSearchValue(search: string) {
    return createAction<string>(applicationConstants.SET_SEARCH_VALUE, search);
  },
  setCountry(country: Option | null) {
    return createAction<Option | null>(applicationConstants.SET_COUNTRY, country);
  },
  setPoliticalInclination(politicalInclination: Option | null) {
    return createAction<Option | null>(
      applicationConstants.SET_POLITICAL_INCLINATION,
      politicalInclination,
    );
  },
  getData(tableData: Table) {
    return createAction(applicationConstants.GET_TABLE_DATA, tableData);
  },
};

export interface ToggleSidebar {
  toggleSidebar: () => void;
}

export interface SetMainTabValue {
  setMainTabValue: (value: string) => void;
}

export interface SetSearchValue {
  setSearchValue: (value: string) => void;
}

export interface SetCountry {
  setCountry: (option: Option | null) => void;
}

export interface SetPoliticalInclination {
  setPoliticalInclination: (politicalInclination: Option | null) => void;
}

export interface GetData {
  getData: (tableData: Table) => void;
}
