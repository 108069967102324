import React from 'react';
import { Portions } from '../Portions';

import styles from './styles.module.scss';

const PORTIONS_LIST = [
  {
    title: 'Geography',
    list: [
      { label: 'United States', value: 35 },
      { label: 'India', value: 24 },
      { label: 'Mexico', value: 41 },
    ],
  },
  {
    title: 'Device Type',
    list: [
      { label: 'Phone', value: 55 },
      { label: 'Tablet', value: 17 },
      { label: 'Desktop', value: 20 },
      { label: 'Other Embedded', value: 1 },
    ],
  },
  {
    title: 'Creative Type',
    list: [
      { label: 'HTML', value: 8 },
      { label: 'Native', value: 23 },
      { label: 'Ticker Banner', value: 23 },
      { label: 'Images', value: 17 },
      { label: 'GOTV', value: 3 },
      { label: 'Audio', value: 3 },
    ],
  },
  {
    title: 'Channel',
    list: [
      { label: 'App', value: 36 },
      { label: 'Web', value: 64 },
    ],
  },
];

export const PortionsList = () => (
  <ul className={`row align-items-stretch mb-4 ${styles.list}`}>
    {PORTIONS_LIST.map((portions) => (
      <li className={`col-3 ${styles.item}`}>
        <Portions title={portions.title} list={portions.list} />
      </li>
    ))}
  </ul>
);
