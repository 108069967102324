import React from 'react';
import { ParamsCard } from 'factor';

import styles from './styles.module.scss';
import { SearchFilter } from '../SearchFilter';
import { SidebarHeader } from './SidebarHeader';

const ALL_INVENTORIES_PARAMS = [
  { label: 'Publisher', value: '21' },
  { label: 'Sites', value: '21' },
  { label: 'Apps', value: '21' },
  { label: 'Uniques', value: '87' },
];

export const Sidebar = () => (
  <div className={styles.container}>
    <ParamsCard
      className={styles.card}
      isBlue
      title="All Inventories"
      list={ALL_INVENTORIES_PARAMS}
    />
    <SidebarHeader title="Search" onCloseClick={() => console.log('close search')} />
    <SearchFilter />
  </div>
);
