import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { auth, AuthState } from './auth/reducer';
import { app, ApplicationState } from './app/reducer';

export interface AppState {
  auth: AuthState;
  app: ApplicationState;
}

const reducer = combineReducers({
  auth,
  app,
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const store = createStore<any, any, any, any>(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);
