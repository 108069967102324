import axios, { AxiosInstance } from 'axios';

import { localStorageService } from 'services/localStorage';
import { HEADER_TOKEN_NAME } from 'config';

export type IQMInstanceConfigParams = {
  apiToken: string;
  domainURL: string;
};

export type Params = { [key: string]: string | number };

let IQMInstance: AxiosInstance;

export function createIqmInstance(config: IQMInstanceConfigParams) {
  const instance = axios.create({
    ...config,
    baseURL: `https://${config.domainURL}/api`,
    headers: {
      [HEADER_TOKEN_NAME]: config.apiToken,
    },
  });

  instance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        const res = {
          response: {
            data: response,
          },
        };

        throw res;
      }
      if (response.response.status === 401) {
        localStorageService.removeBaseInfo();
        window.location.reload();
      }
      return Promise.reject(response);
    },
  );

  IQMInstance = instance;
}

export function getInstance(): AxiosInstance {
  return IQMInstance || axios;
}

export const API = {};
