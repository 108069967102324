import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { routes } from 'routes';

const PrivateRoutesComponent = () => {
  return (
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />
      ))}
    </Switch>
  );
};

export const PrivateRoutes = PrivateRoutesComponent;
