import { Table } from '../../models/Table';

const tableData: Table = {
  title: 'Open Exchange',
  unsortedData: {
    total: true,
    id: 'total',
    ecpm: '$1.30',
    matchRate: '100%',
    whiteList: '23,000',
    blackList: '8,500',
    rowClassName: '_aggregated',
  },
  data: [
    {
      audienceName: 'Campaign 1 Audience',
      ecpm: '$0.30',
      matchRate: '100%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'Geo-Farmed',
      ecpm: '$0.50',
      matchRate: '100%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'Extreme Left DC',
      ecpm: '$0.71',
      matchRate: '100%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'Expanded_Extreme Left DC',
      ecpm: '$0.52',
      matchRate: '99%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'Wordscapes',
      ecpm: '$0.49',
      matchRate: '90%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'Bricks and Balls',
      ecpm: '$0.46',
      matchRate: '91%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'Vidogram',
      ecpm: '$0.30',
      matchRate: '100%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'iFunny - fresh memes, gifs and videos',
      ecpm: '$0.75',
      matchRate: '100%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'SkyVPN-Best Free VPN Proxy for Secure WiFi Hotspot',
      ecpm: '$0.71',
      matchRate: '100%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'TextNow: Call + Text Unlimited',
      ecpm: '$0.52',
      matchRate: '99%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'Wordscapes',
      ecpm: '$0.49',
      matchRate: '90%',
      whiteList: '23,000',
      blackList: '8,500',
    },
    {
      audienceName: 'Bricks and Balls',
      ecpm: '$0.46',
      matchRate: '91%',
      whiteList: '23,000',
      blackList: '8,500',
    },
  ],
};

/* eslint-disable */
tableData.data.forEach((item, i) => {
  item.id = i < 10 ? `0${i}` : i;
});
/* eslint-enable */

export { tableData };

export const testOptions = [
  { label: 'Option 1', value: 'Option 1' },
  { label: 'Option 2', value: 'Option 2' },
  { label: 'Option 3', value: 'Option 3' },
];

export const locationOptions = [
  { value: 22, label: 'State' },
  { value: 23, label: 'City' },
  { value: 17, label: 'Zip Code' },
  { value: 18, label: 'House District' },
  { value: 19, label: 'Congress District' },
  { value: 20, label: 'Senate District' },
  { value: 21, label: 'County' },
  { value: 24, label: 'Polling Booth' },
  { value: 241, label: 'Custom Location' },
];
