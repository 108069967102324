import { MainPage } from 'pages/MainPage';
import { NoMatchPage } from 'pages/NoMatchPage';

export type Route = {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
  private?: boolean;
};

export const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: MainPage,
  },
  {
    path: '*',
    exact: true,
    component: NoMatchPage,
  },
];
